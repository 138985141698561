import { get } from 'lodash'

import CookieService from 'services/cookie.service'
import LocalStorageService from 'services/localStorage.service'

import {
  SortOptionType,
  StackOptionType,
  ViewType,
  SORT,
  STACK,
  VIEW,
  LOCKING,
  HUMAN_GAME,
  FormatType,
  FORMAT,
} from 'types/deck'
import { PRICE_SOURCE } from 'types/active'

import { ControlledStackOptionsType, DEFAULT_CONTROLLED_STACK_OPTIONS } from 'types/controlledStacks'
import { ImageSize } from 'utils/ImageUrl'

// Cookie and LocalStorage keys
const sortKey = 'deckSorting'
const sortDirectionKey = 'deckSortDirection'
const viewKey = 'deckView'
const stackKey = 'deckStack'
const priceKey = 'deckPrices' // This name is silly as the prices are used elsewhere
const deckLockKey = 'deckLocking'
const collectionPriceKey = 'collectionPrice'
const collectionGameKey = 'collectionGame'

const scryfallImagePreference = 'scryfall-image-preference'
const deckSearchOrderBy = 'deck-search-order-by'
const deckSearchAscending = 'deck-search-ascending'
const selectedTemplates = 'selected-cat-templates'

export const newDeckFormat = 'tb-latest-format'
export const newDeckPrivate = 'tb-latest-private'
export const newDeckUnlisted = 'tb-latest-unlisted'
export const newDeckTheorycrafted = 'tb-latest-theorycrafted'
export const newDeckGameType = 'tb-latest-game-type'
export const newDeckCommanderBracket = 'tb-commander-bracket'
export const packageTabKey = 'tb-package-tab'
export const hideDefaultColorTagsKey = 'tb-hide-default-color-tags'

// prettier-ignore
const getSettingFromNextCookie = <T>(context: any = {}, key: string): T | undefined => get(context, `req.cookies[${key}]`) ?? get(context, `req.cookies[${key}]`) ?? get(context, `req.cookies.settings[${key}]`) ?? undefined
const getSettingsFromCookie = (key: string) => get(CookieService.get('settings'), key)

export const getSorting = (context?: any): SortOptionType =>
  Number(
    getSettingFromNextCookie<SortOptionType>(context, sortKey) ??
      CookieService.get(sortKey) ??
      get(CookieService.get('settings'), sortKey) ??
      SORT.ALPHA,
  ) as SortOptionType

export const getView = (context?: any): ViewType =>
  Number(
    getSettingFromNextCookie<ViewType>(context, viewKey) ??
      CookieService.get(viewKey) ??
      get(CookieService.get('settings'), viewKey) ??
      VIEW.CARD,
  ) as ViewType

export const getStack = (context?: any): StackOptionType =>
  Number(
    getSettingFromNextCookie<StackOptionType>(context, stackKey) ??
      CookieService.get(stackKey) ??
      get(CookieService.get('settings'), stackKey) ??
      STACK.CUSTOM,
  ) as StackOptionType

export const getSortingDirection = (context?: any): boolean =>
  getSettingFromNextCookie<string>(context, sortDirectionKey) === 'true'

export const getControlledStackOptions = (deckId?: string | number): ControlledStackOptionsType => {
  let controlledStackOptions: ControlledStackOptionsType = (LocalStorageService.getObject('controlledStacks') || {})[
    `${deckId}`
  ]
  if (!controlledStackOptions) controlledStackOptions = { ...DEFAULT_CONTROLLED_STACK_OPTIONS }
  if (!controlledStackOptions.columnCount) controlledStackOptions.columnCount = 0
  if (!controlledStackOptions.columns) controlledStackOptions.columns = []
  if (!controlledStackOptions.controlled) controlledStackOptions.controlled = false
  if (!controlledStackOptions.showEmptyColumns) controlledStackOptions.showEmptyColumns = false

  return controlledStackOptions
}

export const getPriceSources = (priceSources?: Array<number>) => {
  if (priceSources) return priceSources

  const userSetPriceSources = CookieService.get(priceKey)
  const settingsPriceSource = get(CookieService.get('settings'), priceKey)

  if (userSetPriceSources) return userSetPriceSources
  if (settingsPriceSource) return settingsPriceSource

  return [PRICE_SOURCE.CK, PRICE_SOURCE.TCG]
}

export const getHideDefaultColorTags = (context?: any) => {
  const value =
    getSettingFromNextCookie<boolean>(context, hideDefaultColorTagsKey) ??
    CookieService.get(hideDefaultColorTagsKey) ??
    false

  return cacheToBoolean(value)
}

export const getLeftPanelOpenDefault = (): boolean => {
  if (typeof window === 'undefined') return false
  if (!window.location.pathname.startsWith('/decks/')) return false

  const lockedValue = get(CookieService.get('settings'), deckLockKey)
  const viewPortWidth = window.innerWidth

  // Small screens ignore this
  if (viewPortWidth <= 1000) return false

  if (lockedValue === LOCKING.Left && viewPortWidth > 1000) return true
  if (lockedValue === LOCKING.Both && viewPortWidth > 1000) return true

  return false
}

export const getCollectionPrice = (): number => Number(getSettingsFromCookie(collectionPriceKey) ?? PRICE_SOURCE.CK)
export const getCollectionGame = (): number => Number(getSettingsFromCookie(collectionGameKey) ?? HUMAN_GAME.Paper)

export const setLocalSorting = (sort: SortOptionType) => CookieService.set(sortKey, sort)
export const setLocalSortDirectionDescending = (descending: boolean) => CookieService.set(sortDirectionKey, descending)
export const setLocalView = (view: ViewType) => CookieService.set(viewKey, view)
export const setLocalStack = (stack: StackOptionType) => CookieService.set(stackKey, stack)
export const setLocalPriceSources = (priceSources: Array<number>) => CookieService.set(priceKey, priceSources)

export const setImageSizePreference = (size: ImageSize) => CookieService.set(scryfallImagePreference, size)
export const getImageSizePreference = (): ImageSize => {
  const value: any = CookieService.get(scryfallImagePreference) || 'normal'

  // Extra logic to be sure we're always returning clean values (since the cookie could be old or anything)
  if (value === 'small') return 'small'
  if (value === 'large') return 'large'

  return 'normal'
}

// prettier-ignore
export const getSelectedTemplates = (): Array<number | null> => (LocalStorageService.get(selectedTemplates) ?? '').split(',').map((value: string) => Number(value) ?? undefined)
export const setSelectedTemplates = (ids: number[]) => LocalStorageService.set(selectedTemplates, `${ids.join(',')}`)

export const setNewDeckFormat = (format: FormatType) => CookieService.set(newDeckFormat, `${format}`)
export const getNewDeckFormat = () => (Number(CookieService.get(newDeckFormat)) || FORMAT.EDH) as FormatType

export const setDeckSearchOrderBy = (value: string) => LocalStorageService.set(deckSearchOrderBy, value)
export const getDeckSearchOrderBy = (): string | null => LocalStorageService.get(deckSearchOrderBy)

export const setDeckSearchAscending = (value: boolean) => LocalStorageService.set(deckSearchAscending, `${value}`)
export const getDeckSearchAscending = (): boolean => LocalStorageService.get(deckSearchAscending) === 'true'

export const getDeckSearchOrderingQueryParameter = () => {
  const orderBy = getDeckSearchOrderBy()

  if (!orderBy) return ''

  const ascending = getDeckSearchAscending()

  return `orderBy=${ascending ? '' : '-'}${orderBy}`
}

export const getBooleanFlagFromLocalStorage = (key: string) => cacheToBoolean(LocalStorageService.get(key))
export const cacheToBoolean = (value: any) => `${value}` === 'true'
