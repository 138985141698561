import { invert } from 'lodash'
import { Color, Legality, FaceType } from 'services/apiTypes/card.types'
import CookieService from 'services/cookie.service'

export type ColorLabelType = { name: string; color: string }
export const defaultColorLabel: ColorLabelType = {
  name: '',
  color: '#656565',
}

export type Finishes = 'Normal' | 'Foil' | 'Etched'
export type SaveStatus = 'saving' | 'saved' | 'error'

export type CardType = {
  name: string
  displayName: string | null
  cmc: number
  rarity: string
  castingCost: Array<string>
  colorIdentity: Array<Color>
  colors: Array<Color>
  manaProduction: Record<string, number>
  text: string
  flavor: string
  set: string
  setCode: string
  setDate: string
  setType: string
  mtgoCode: string
  id: string
  deckRelationId: string
  cardId: string
  oracleCardId: string | number
  uid: string
  multiverseid: number
  imgurl: string
  artist: string
  superTypes: Array<string>
  subTypes: Array<string>
  types: Array<string>
  power: string
  toughness: string
  layout: string
  loyalty: string
  canlanderPoints: number | null
  legalities: Record<string, Legality>
  names: Array<string>
  gameChanger: boolean
  extraTurns: boolean
  tutor: boolean
  massLandDenial: boolean
  twoCardComboSingelton: boolean
  twoCardComboIds: string[]
  qty: number
  modifier: Finishes
  options: Array<Finishes>
  categories: Array<string>
  globalCategories: Array<string>
  typeCategory: string // The default type picked for a card, especially if it has more than one type
  front: CardType | null
  back: CardType | null
  meldBack: CardType | null
  colorLabel: ColorLabelType
  prices: Record<string, any>
  faces: Array<FaceType>
  ids: {
    ckNormalId: number
    ckFoilId: number
    tcgId: number
    mtgoNormalId: number
    mtgoFoilId: number
    cmEd: string
    scg: string
    scgFoil: string
  }
  owned: number
  customCmc: number | null
  collectorNumber: string
  salt: number
  tokens: number[] // card ids
  companion: boolean
  flippedDefault: boolean
  otherPrintingQty: number // this is _only_ ever set in the search results
  createdAt?: string | null
  updatedAt?: string | null
  deletedAt?: string | null
  defaultCategory: string | null
  scryfallImageHash: string | null
}

export const OWNED = {
  NO: 0,
  YES: 1,
  PRINTING: 2,
}

export const HUMAN_OWNED = {
  'Not Owned': 0,
  Owned: 1,
  'Other Printing': 2,
}

export const defaultCard: CardType = {
  name: '',
  displayName: null,
  cmc: 0,
  castingCost: [],
  colorIdentity: [],
  colors: [],
  manaProduction: {},
  text: '',
  flavor: '',
  set: '',
  setCode: '',
  setDate: '',
  setType: '',
  mtgoCode: '',
  id: '',
  deckRelationId: 'not-in-deck-',
  cardId: '',
  oracleCardId: '',
  uid: '',
  multiverseid: 0,
  imgurl: '',
  artist: '',
  superTypes: [],
  subTypes: [],
  types: [],
  power: '',
  toughness: '',
  loyalty: '',
  canlanderPoints: null,
  rarity: '',
  layout: 'normal',
  names: [],

  // Bracket shit
  gameChanger: false,
  extraTurns: false,
  tutor: false,
  massLandDenial: false,
  twoCardComboSingelton: false,
  twoCardComboIds: [],

  qty: 0,
  modifier: 'Normal',
  options: [],
  categories: [],
  globalCategories: [],
  typeCategory: '',
  legalities: {},
  front: null,
  back: null,
  faces: [],
  meldBack: null,
  prices: {
    ck: 0.0,
    ckFoil: 0.0,
    tcg: 0.0,
    tcgFoil: 0.0,
    mtgo: 0.0,
    mtgoFoil: 0.0,
    cm: 0.0,
    cmFoil: 0.0,
    mp: 0.0,
    mpFoil: 0.0,
    tcgland: 0.0,
    tcglandFoil: 0.0,
  },
  colorLabel: {
    name: '',
    color: '#656565',
  },
  ids: {
    ckNormalId: 0,
    ckFoilId: 0,
    tcgId: 0,
    mtgoNormalId: 0,
    mtgoFoilId: 0,
    cmEd: '',
    scg: '',
    scgFoil: '',
  },
  owned: OWNED.NO,
  customCmc: null,
  collectorNumber: '',
  salt: 0,
  tokens: [],
  companion: false,
  flippedDefault: false,
  otherPrintingQty: 0, // only set in search

  createdAt: null,
  updatedAt: null,
  deletedAt: null,

  defaultCategory: null,
  scryfallImageHash: null,
}

export const defaultFace: FaceType = {
  name: '',
  power: '',
  toughness: '',
  subTypes: [],
  types: [],
  superTypes: [],
  text: '',
  flavor: '',
  castingCost: [],
}

export type StackOptionType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20

export const STACK: Record<string, StackOptionType> = {
  CUSTOM: 0,
  TYPES: 1,
  CMC: 2,
  FULL_DECK: 3,
  COLOR_LABEL: 4,
  COLLECTION: 5,
  MULTIPLE: 6,
  RARITY: 7,
  SALT: 8,
  PRICE: 9,
  COLOR: 10,
  COLOR_IDENTITY: 11,
  DATE_ADDED: 12,
  EDITION_NAME: 13,
  ARTIST_NAME: 14,
  POWER: 15,
  TOUGHNESS: 16,
  CATEGORY_QUANTITY: 17,
  MANA_VALUE_SPELLS: 18,
  CANLANDER_POINTS: 19,
  EDH_BRACKET_INFO: 20,
}

export const TIME_STACKS = [STACK.DATE_ADDED]
export const NUMERIC_STACKS = [
  STACK.CMC,
  STACK.POWER,
  STACK.TOUGHNESS,
  STACK.CATEGORY_QUANTITY,
  STACK.MANA_VALUE_SPELLS,
  STACK.CANLANDER_POINTS,
]
export const AUTH_STACKS = [STACK.COLLECTION]

export const HUMAN_STACK: Record<string, StackOptionType> = {
  Categories: 0,
  'Categories (multiple)': 6,
  Type: 1,
  'Mana value': 2,
  'Mana value (spells)': 18,
  'Full deck': 3,
  'Color tag': 4,
  'EDH Bracket Info': 20,
  Collection: 5,
  Rarity: 7,
  Salt: 8,
  Price: 9,
  Color: 10,
  Identity: 11,
  'Date added': 12,
  'Edition name': 13,
  'Artist name': 14,
  Power: 15,
  Toughness: 16,
  'Category quantity': 17,
  'Canlander pts': 19,
}

export type ViewType = 0 | 1 | 2 | 3 | 4 | 5

export const VIEW: Record<string, ViewType> = {
  CARD: 0,
  TEXT: 1,
  GRID: 2,
  SCROLL: 3,
  SPREADSHEET: 4,
  BREWING: 5,
}

export type SortOptionType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14

export type SortKeyType =
  | 'ALPHA'
  | 'CMC'
  | 'PRICE'
  | 'SET'
  | 'COLOR'
  | 'COLOR_LABEL'
  | 'COLLECTION'
  | 'SALT'
  | 'DATE_ADDED'
  | 'TYPE'
  | 'POWER'
  | 'TOUGHNESS'
  | 'CATEGORY_QUANTITY'
  | 'CARD_MODIFIER'
  | 'IDENTITY'

export const SORT: Record<SortKeyType, SortOptionType> = {
  ALPHA: 0,
  CMC: 1,
  PRICE: 2,
  SET: 3,
  COLOR: 4,
  COLOR_LABEL: 5,
  COLLECTION: 6,
  SALT: 7,
  DATE_ADDED: 8,
  TYPE: 9,
  POWER: 10,
  TOUGHNESS: 11,
  CATEGORY_QUANTITY: 12,
  CARD_MODIFIER: 13,
  IDENTITY: 14,
}

export const AUTH_SORTS = [SORT.COLLECTION]

export type DeckNameType = string

export type DescriptionType = string

export type FormatType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25

export const FORMAT: Record<string, FormatType> = {
  STANDARD: 1,
  MODERN: 2,
  EDH: 3,
  LEGACY: 4,
  VINTAGE: 5,
  PAUPER: 6,
  CUSTOM: 7,
  FRONTIER: 8,
  FUTURE_STANDARD: 9,
  PENNY: 10,
  ONE_V_ONE_COMMANDER: 11,
  DUEL_COMMANDER: 12,
  BRAWL: 13,
  OATHBREAKER: 14,
  PIONEER: 15,
  HISTORIC: 16,
  PAUPER_EDH: 17,
  ALCHEMY: 18,
  EXPLORER: 19,
  HISTORIC_BRAWL: 20,
  GLADIATOR: 21,
  PREMODERN: 22,
  PREDH: 23,
  TIMELESS: 24,
  CANLANDER: 25,
}

export const BRACKET_NAME: Record<number, string> = {
  1: 'Exhibition (1)',
  2: 'Core (2)',
  3: 'Upgraded (3)',
  4: 'Optimized (4)',
  5: 'cEDH (5)',
}

export const HUMAN_VIEW: Record<string, ViewType> = {
  Card: 0,
  Text: 1,
  Grid: 2,
  Scroll: 3,
  Table: 4,
  Brewer: 5,
}

export const HUMAN_SORT: Record<string, number> = {
  Alphabet: 0,
  'Mana value': 1,
  Type: 9,
  Price: 2,
  Set: 3,
  Color: 4,
  Identity: 14,
  'Color tag': 5,
  Collection: 6,
  Salt: 7,
  'Date added': 8,
  Power: 10,
  Toughness: 11,
  'Category quantity': 12,
  'Card finish': 13,
}

export const HUMAN_FORMAT: Record<string, FormatType> = {
  'Commander / EDH': 3,
  Standard: 1,
  Modern: 2,
  Legacy: 4,
  Custom: 7,

  '1v1 Commander': 11,
  Alchemy: 18,
  'Standard Brawl': 13,
  Pauper: 6,
  'Canadian Highlander': 25,
  'Duel Commander': 12,
  Explorer: 19,
  Frontier: 8,
  'Future Standard': 9,
  Gladiator: 21,
  Historic: 16,
  'Penny Dreadful': 10,
  Oathbreaker: 14,
  Brawl: 20,
  'Pauper EDH': 17,
  Pioneer: 15,
  PreDH: 23,
  Premodern: 22,
  Vintage: 5,
  Timeless: 24,
}

export const HUMAN_SHORT_FORMAT: Record<string, FormatType> = {
  Standard: 1,
  Modern: 2,
  EDH: 3,
  Legacy: 4,
  Vintage: 5,
  Pauper: 6,
  Custom: 7,
  Frontier: 8,
  Future: 9,
  Penny: 10,
  '1v1': 11,
  Duel: 12,
  'Strd. Brawl': 13,
  Oathbreaker: 14,
  Pioneer: 15,
  Historic: 16,
  PDH: 17,
  Alchemy: 18,
  Explorer: 19,
  Brawl: 20,
  Gladiator: 21,
  Premodern: 22,
  PreDH: 23,
  Timeless: 24,
  'Canlander': 25,
}

export const INTERNAL_FORMAT_NAME: Record<string, FormatType> = {
  standard: 1,
  modern: 2,
  commander: 3,
  legacy: 4,
  vintage: 5,
  pauper: 6,
  custom: 7,
  frontier: 8,
  future: 9,
  penny: 10,
  '1v1': 11,
  duel: 12,
  brawl: 13,
  oathbreaker: 14,
  pioneer: 15,
  historic: 16,
  paupercommander: 17,
  alchemy: 18,
  explorer: 19,
  historicbrawl: 20,
  gladiator: 21,
  premodern: 22,
  predh: 23,
  timeless: 24,
  canlander: 25,
}

export const SCRYFALL_FORMAT: Record<string, FormatType> = {
  standard: 1,
  modern: 2,
  commander: 3,
  legacy: 4,
  vintage: 5,
  pauper: 6,
  custom: 7,
  frontier: 8,
  future: 9,
  penny: 10,
  '1v1': 11,
  duel: 12,
  standardbrawl: 13,
  oathbreaker: 14,
  pioneer: 15,
  historic: 16,
  paupercommander: 17,
  alchemy: 18,
  explorer: 19,
  brawl: 20,
  gladiator: 21,
  premodern: 22,
  predh: 23,
  timeless: 24,
  // vintage: 25, // We want canadian highlander to use vintage legality from scryfall, can't have the same key though. Manually (grossly) fixing this in the inversion below
}

export const HUMAN_READABLE_FORMAT = invert(HUMAN_FORMAT) as Record<FormatType, string>
export const FORMAT_IDS = invert(INTERNAL_FORMAT_NAME) as Record<FormatType, string>
export const FORMAT_IDS_SCRYFALL = { ...invert(SCRYFALL_FORMAT), 25: 'vintage' } as Record<FormatType, string>

export type GameType = 1 | 2 | 3

export const HUMAN_GAME: Record<string, GameType> = {
  Paper: 1,
  MTGO: 2,
  Arena: 3,
}

export const GAME_IDS = invert(HUMAN_GAME) as Record<GameType, string>

export const TOOLBAR = {
  Horizontal: 1,
  Vertical: 2,
}

export const LOCKING = {
  Neither: 1,
  Left: 2,
  Right: 3,
  Both: 4,
}
export const LEFT_LOCKING = [LOCKING.Left, LOCKING.Both]
export const RIGHT_LOCKING = [LOCKING.Right, LOCKING.Both]

export const EDH_FORMATS = [3, 11, 12, 13, 14, 17, 20, 23]
export const MULTIPLAYER_FORMATS = [3, 12, 13, 14, 17, 20, 23]
export const ARENA_PRIOIRTY_FORMATS = [18]
export const NONSCRYFALL_FORMATS = [7, 14]

export type CategoryType = {
  id: number | null
  name: string
  includedInDeck: boolean
  includedInPrice: boolean
  isPremier: boolean
}

export const defaultCategories: Record<string, CategoryType> = {
  Sideboard: {
    id: null,
    includedInDeck: true,
    includedInPrice: true,
    isPremier: false,
    name: 'Sideboard',
  },
  Maybeboard: {
    id: null,
    includedInDeck: false,
    includedInPrice: false,
    isPremier: false,
    name: 'Maybeboard',
  },
}

// prettier-ignore
const notInlcudedInDeckByDefaultCategories: string[] = ['Maybeboard', 'Attraction', 'Stickers', 'Tokens & Extras', 'Planar Deck']
const notInlcudedInPriceByDefaultCategories: string[] = ['Maybeboard']

const includedInPrimaryByDefault: string[] = ['Commander']

export const getCategoryName = (card: CardType): string => {
  const defaultCategory = card.categories[0]
  const { noUseDefaultCategories = false } = CookieService.get('settings') || {}

  if (defaultCategory && defaultCategory !== 'Plane' && defaultCategory !== 'Phenomenon') return defaultCategory
  if (card.typeCategory === 'Plane' || card.typeCategory === 'Phenomenon') return 'Planar Deck'
  if (card.defaultCategory && !noUseDefaultCategories) return card.defaultCategory

  return card.typeCategory
}

export const generateDefaultCategory = (name: string = ''): CategoryType => ({
  id: null,
  includedInDeck: !notInlcudedInDeckByDefaultCategories.includes(name),
  includedInPrice: !notInlcudedInPriceByDefaultCategories.includes(name),
  isPremier: includedInPrimaryByDefault.includes(name),
  name,
})

export const CREATE_CATEGORY_TEXT = 'Create new category...'

export const DEFAULT_DECK_CARD_IMAGE =
  'https://storage.googleapis.com/topdekt-user/images/archidekt_deck_card_shadow.jpg'

export type StackType = {
  name: string
  price: number
  quantity: number
  cardIds: string[]
  isPremier: boolean
  includedInDeck: boolean
  includedInPrice: boolean
}

export const DECK_PAGE_STYLES = {
  MAX_WIDTH: 2300,
  WIDTH_DECIMAL: 0.95,
  WIDTH_PERCENTAGE: '95%',
  DECK_CONTAINER_PADDING: 20,
  DECK_CONTAINER_GAP: 20,
  EDITOR_TOOLBAR_WIDTH: 50,
  SCROLL_BAR_WIDTH: 10,
}

export type ADD_TYPES = 1 | 2 | 3

export const ADD_METHODS: Record<string, ADD_TYPES> = {
  QUICK_ADD: 1,
  DETAILED_ADD: 2,
  SYNTAX_QUICK_ADD: 3,
}

export const ADD_METHOD_NAMES: Record<ADD_TYPES, string> = {
  1: 'Quick add',
  2: 'Detailed add',
  3: 'Syntax quick add',
}
